import Vue from 'vue'

Vue.filter('ucfirst', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});


Vue.filter("normalizeSentence", function (value) {
  if (!value) return "";
  return value
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/\b\w/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
});


Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toString().toUpperCase()
});


Vue.filter("toCurrency", function (value, option = null) {
  if (typeof value !== "number") {
    return value;
  }

  if (option == "discount_percentage") {
    return `↓ %${value}`;
  }

  let options = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  };

  if (option == "signDisplay") {
    options.signDisplay = "exceptZero";
  }

  var formatter = new Intl.NumberFormat("nl-BE", options);

  let val = formatter.format(value);

  if (option == "remove_thousand_separator" && val.toString().match(/\,\d{2}$/)) {
    return val.toString().replace(".", "");
  } else if (option == "discount_price") {
    return `↓ ${val}`;
  }
  return val;
});


Vue.filter('FixedNumber', function (value, digits = 2) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('nl-BE', {
    maximumFractionDigits: digits,
  });

  return formatter.format(value);
});


Vue.filter('removeAllWhiteSpaces', function (value) {
  if (!value) return ''
  return value.toString().replace(/\s/g, "")
});


// http://www.google.com -> http://www.google.com
// ftp://google.com      -> ftp://google.com
// www.google.com        -> http://www.google.com
// google.com            -> http://google.com
Vue.filter('addUriScheme', function (value) {
  if (!value)
    return ''

  return (value.indexOf('://') === -1) ? 'https://' + value : value;
});


// Pluralize filter
Vue.filter('pluralize', (word, amount) => (amount > 1 || amount == 0) ? `${word}s` : word);
