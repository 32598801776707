import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { nl, en, fr } from './languages';

Vue.use(VueI18n)

const messages = {
  nl, en, fr
}

export default new VueI18n({
  // locale: getBrowserLocale(),
  // locale: 'de',
  locale: getLang(),
  fallbackLocale: 'nl',
  messages,
})

function getLang() {
  const lang = localStorage.getItem("lang");
  return lang ?? getBrowserLocale();
}

function getBrowserLocale() {
  const navigatorLocale =
    navigator.languages !== undefined ?
      navigator.languages[0] :
      navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const browserLang = navigatorLocale.trim().split(/-|_/)[0];
  localStorage.setItem("lang", browserLang);
  return browserLang;
}