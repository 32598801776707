export const Role = {
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  RestAdmin: 'RestAdmin',
  InvoiceAdmin: 'InvoiceAdmin',
  MenuAdmin: 'MenuAdmin',
  OrderAdmin: 'OrderAdmin',
  CourierAdmin: 'CourierAdmin',
  TotalViewerBelgium: 'TotalViewerBelgium',
}
