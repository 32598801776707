import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro'

import App from './App'
import router from './router/index'
import store from './store/index'
import i18n from './plugins/i18n.js'
import VueConfirmDialog from 'vue-confirm-dialog'
import { freeSet as icons } from '@coreui/icons'  // import { iconsSet as icons} from './assets/icons/icons.js'

import backend from "./router/backend.js";  // Api Routing Table
import { axiosInstance } from './helper/axios.js' // Global Axios
import { globalFunc, globalVar } from './helper/global.js'  // Global Functions/Variables

import { AlertSection, MIcon, Spinner } from './components/';  // MC Global Components

import "./filters/global.js"; // Global filters

import { mapState, mapGetters, mapActions } from "vuex";

// import OneSignalVue from 'onesignal-vue'

// Smartlook-client
import smartlookClient from 'smartlook-client'

// import Hotjar from 'vue-hotjar';
// Vue.use (Hotjar, {
//   id: process.env.VUE_APP_HOTJAR_ID,
//   isProduction: process.env.NODE_ENV==='production',
//   snippetVersion: 6
// })


// Vue Select
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', '❌'),
  },
});
Vue.component('v-select', vSelect);


// Vuelidate Error Extractor 2
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  // Define common validation messages.
  messages: {
    required: "{attribute} is required!",
    email: "{attribute} is not a valid Email address!",
    minLength: "{attribute} is a required field and must be at least {min} characters.",
    minDate: "{attribute} must not be less than Today or From Date!",
    validIBAN: "{attribute} is invalid!",
    minValue: "{attribute} must be at least {min}.",
    checkVAT: "{attribute} is invalid!",
    first_type_must_have_image: "The first type must have an image.",
    hasHouseNumber: "{attribute} must have a house number.",
    voucherMustBeCompatibleWithRestaurant: "The voucher must be compatible with the restaurant.",
  }
});


// Vue Toastification v1 (Vue 3)
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!
const toast_options = {
  // position: POSITION.TOP_CENTER,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
}

import VueImg from 'v-img';
Vue.use(VueImg);

import VueSmoothScroll from 'vue2-smooth-scroll'  // https://www.npmjs.com/package/vue2-smooth-scroll
import VueSignaturePad from 'vue-signature-pad';
import moment from 'moment'

// Use
Vue.use(CoreuiVuePro)
Vue.use(VueConfirmDialog)
Vue.use(VueSmoothScroll, { duration: 400, updateHistory: false })
Vue.use(VueSignaturePad)
Vue.use(Toast, toast_options);
// Vue.use(OneSignalVue);

// Components
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('alert-section', AlertSection);
Vue.component('mc-spinner', Spinner);
Vue.component('formSummary', templates.multiErrorExtractor.foundation6)
Vue.component('MIcon', MIcon);


// Prototypes
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$backend = backend;
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$globalFunc = globalFunc;
Vue.prototype.$globalVar = globalVar;
Vue.prototype.moment = moment

import Swal from 'sweetalert2'
const mSwal = Swal.mixin({
  confirmButtonText: "Yes",
  confirmButtonColor: "#f36705",

  showCancelButton: true,
  cancelButtonText: "No",
});
Vue.prototype.mSwal = mSwal


// Mixin
// mixin içerisindeki tüm option'lar (data, method, computed prop…) component'in kendi option'ları içerisinde 'mixed' olarak yer alır.
Vue.mixin({
  computed: {
    ...mapGetters('auth', ["restrictedUser"]),

    ...mapState({
      authUser: state => state.auth.userData,
      authUserRoles: state => state.auth.userData && state.auth.userData.roles.length > 0 ? state.auth.userData.roles.map(role => role.name) : ['Admin'],
      authState: state => state.auth.authState,
      authCountries: (state) => {
        const userAuthorizedCountries = state.auth.userData?.authorized_countries;
        if (!userAuthorizedCountries || userAuthorizedCountries.length === 0) {
          return globalVar.countries;
        }

        return globalVar.countries.filter(country => userAuthorizedCountries.includes(country.value));
      },
    }),

    isProduction() { return process.env.NODE_ENV === 'production' },
    isUserLoggedIn() {
      var authToken = localStorage.getItem("authToken");
      return authToken !== null && authToken !== undefined;
    },
  }
})

// Firebase Cloud Messaging (FCM)
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from './firebaseConfig';

const firebaseApp = initializeApp(firebaseConfig.webAppConfig);
const messaging = getMessaging(firebaseApp);


new Vue({
  el: "#app",
  router,
  store,
  icons,
  i18n,
  template: "<App/>",
  components: {
    App,
  },

  watch: {
    "$store.state.auth.authState": {
      handler: function (val) {
        if (val) {
          this.getFirebaseUserToken();

          if (!("Notification" in window)) {
            console.log("This browser does not support system notifications.");
          } else if (Notification.permission === "granted") {
            this.setFirebaseListener();
          }

          !this.restrictedUser && this.getCrmCount();
        }
      },
    },
  },

  created() {
    Object.defineProperty(String.prototype, "capitalize", {
      value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
      },
      enumerable: false,
    });

    // Convert slug to title
    Object.defineProperty(String.prototype, "slugToTitle", {
      value: function () {
        return this.split("-").join(" ").capitalize();
      },
    });

    Array.prototype.pluck = function (key) {
      return this.map((i) => i[key]);
    };

    Array.prototype.toggle = function (value) {
      var index = this.indexOf(value);

      if (index === -1) {
        this.push(value);
      } else {
        this.splice(index, 1);
      }
    };

    Array.prototype.hasMultipleRecords = function (key, value) {
      var recordCount = this.filter(obj => obj[key] === value).length;
      return recordCount > 1;
    };
  },

  /**
  beforeMount() {
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
      notifyButton: {
        enable: false,
      },
      promptOptions: {
        slidedown: {
          prompts: [{ autoPrompt: false }]
        },
      },
    });
  },
  */

  mounted() {
    if (this.isUserLoggedIn) {
      this.getUserData();
    }

    // Smartlook-client
    process.env.VUE_APP_SMARTLOOK_ID != -1 &&
      smartlookClient.init(process.env.VUE_APP_SMARTLOOK_ID);

    /** // Use the listener to react to changes in the user's subscription state
    this.$OneSignal.User.PushSubscription.addEventListener("change",
      async function ({ previous, current }) {
        if (typeof current.id !== "undefined") {
          await axiosInstance.post(backend.PUSHER.ONESIGNAL_SUBSCRIPTION, {
            "player-id": current.id,
            "is-subscribed": current.optedIn,
          });
        }
      }
    );
    */
  },

  methods: {
    ...mapActions("auth", ["getUserData"]),

    getCrmCount() {
      this.$axios.get(this.$backend.CRM.GET_COUNT).then((response) => {
        this.$store.state.crm.hasUnreadMail =
          response.data.unread_emails_count > 0;
        this.$store.state.crm.hasPendingTask =
          response.data.pending_tasks_count > 0;
      });
    },

    getFirebaseUserToken() {
      getToken(messaging, { vapidKey: firebaseConfig.VAPID })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("Token", currentToken);
            this.saveFCMToken(currentToken);
          } else {
            // Request notification permissions from the user
            Notification.requestPermission();
          }
        })
        .catch((err) => {
          if (err instanceof DOMException) {
            // DOMException: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker
            this.getFirebaseUserToken();
          } else {
            console.error(`FCM: ${err.message}`);
          }
        });
    },

    async saveFCMToken(currentToken) {
      await this.$axios
        .post(this.$backend.PUSHER.FCM_SUBSCRIPTION, {
          project: "fcm-menute-admin",
          token: currentToken,
          device: {
            device_type: null,
            device_model: null,
            device_os: null,
            manufacturer: null,
            is_rooted: 0,
            is_notify: 1,
            location: null,
            timezone: null,
            lang: null,
            country: null,
            app_versions: null,
          },
        })
        .catch((err) => {
          console.error(`FCM: ${err.message}`);
        });
    },

    setFirebaseListener() {
      // Firebase Cloud Messaging (FCM) Message event listener

      // Dynamically set the service worker file name based on the environment
      const serviceWorkerFileName = this.isProduction ? "/firebase-messaging-sw.js" : "/firebase-messaging-sw-dev.js";
      navigator.serviceWorker.register(serviceWorkerFileName);

      // # Handle messages when your web app is in the background or the foreground
      navigator.serviceWorker.addEventListener("message", (event) => {
        const payload = event.data;
        const customData = JSON.parse(payload?.data?.json);
        const reqEvent = customData?.event;
        reqEvent === "new-task" && this.getCrmCount();
      });

      // # Handle messages when your web app is in the foreground
      onMessage(messaging, (payload) => {
        // *** The following code is written because the web foreground push notification integrated using Firebase is not popping up.
        // const notificationTitle = payload.notification.title;
        // const notificationOptions = { body: payload.notification.body, icon: payload.notification.icon };
        // var notification = new Notification(notificationTitle, notificationOptions);
        // notification.onclick = function (event) {
        //   event.preventDefault();
        //   payload?.fcmOptions?.link && window.open(payload.fcmOptions.link);
        //   notification.close();
        // };

        // *** Customize notification display using SweetAlert
        const alOption = {
          title: payload.notification.title,
          text: payload.notification.body,
          icon: "info",
          confirmButtonText: "OK",
          showCancelButton: false,
        };

        var customData = JSON.parse(payload?.data?.json);
        if (customData?.custom_link) {
          alOption.footer = `<a href="${customData.custom_link.url}">${customData.custom_link.title || 'Go to the link'} →</a>`;
        }
        this.mSwal.fire(alOption);
      });
    },
  },
});
