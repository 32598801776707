import { axiosInstance } from '../helper/axios.js'
import backend from "../router/backend";

// Smartlook-client
import smartlookClient from 'smartlook-client'

// *.js dosyalarından 'authenticated user' bilgilerine erişmek için eklendi.
export const authService = {
  get currentUser() {
    return JSON.parse(localStorage.getItem("authData"));
  }
};


export default {
  namespaced: true,

  state: {
    authState: false,
    userData: JSON.parse(localStorage.getItem('authData')) || null,
  },

  getters: {
    userData: state => state.userData,
    restrictedUser: state => state.userData?.roles.some(role => role.name === 'TotalViewerBelgium')
  },

  mutations: {
    loggedIn(state, { data, token }) {
      state.authState = true;
      state.userData = data;
      localStorage.setItem("authData", JSON.stringify(data));
      token && localStorage.setItem("authToken", token);

      const userAuthorizedCountries = data.authorized_countries;
      if (userAuthorizedCountries && userAuthorizedCountries.length > 0) {
        const firstAuthorizedCountry = userAuthorizedCountries[0];
        this.commit("setCountry", firstAuthorizedCountry, { root: true });
      }
    },
    loggedOut(state) {
      state.authState = false;
      state.userData = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("authData");
    },
  },

  actions: {

    // User Profile
    async getUserData({ commit, dispatch }) {
      await axiosInstance.post(backend.AUTH.PROFILE)
        .then(({data}) => {
          commit("loggedIn", data);
          dispatch("smartlook");
        })
        .catch(() => {
          localStorage.removeItem("authToken");
          localStorage.removeItem("authData");
        });
    },


    // Login
    async sendLoginRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      const response = await axiosInstance.post(backend.AUTH.LOGIN, data);

      if (response.status === 200 && response.data['2fa'] === false) {
        dispatch("loggedIn", response.data);
      }
      return response;
    },

    // Verify OTP
    async verifyOTP({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      const response = await axiosInstance.post(backend.AUTH.VERIFY_OTP, data);
      if (response.status === 200) {
        dispatch("loggedIn", response.data);
      }
      return response;
    },

    // If login is successful
    loggedIn({ commit, dispatch }, res) {
      commit("loggedIn", res);

      // OneSignal
      // dispatch("onesignal", res.data.user);

      // Initialize Smartlook
      (process.env.VUE_APP_SMARTLOOK_ID != -1) && !smartlookClient.initialized() && smartlookClient.init(process.env.VUE_APP_SMARTLOOK_ID)
      dispatch("smartlook");
    },


    // Logout
    async sendLogoutRequest({ commit }) {
      await axiosInstance.post(backend.AUTH.LOGOUT).then(() => {
        commit("loggedOut");
      });
    },


    // Hotjar
    // hotjar({ state }) {
    //   if (this._vm.$hj) {
    //     this._vm.$hj('identify', state.userData.user.id, {
    //       email: state.userData.user.email,
    //     })
    //   }
    // },


    // Smartlook
    smartlook({ state }) {
      smartlookClient.identify(state.userData.user.id, {
        name: state.userData.user.full_name,
        email: state.userData.user.email,
      })
    },

    /** // OneSignal
    onesignal({}, loggedInUser) {
      // Switch the context to that specific user
      OneSignal.login(`${loggedInUser.id}`);

      // Add usertype as tag
      OneSignal.User.addTag("usertype", loggedInUser.usertype);

      // Add roles as tag
      OneSignal.User.addTags(Object.fromEntries(
        loggedInUser.roles.map((role) => [role.name, true])
      ));

      // Subscribes the current user to push notifications
      OneSignal.User.PushSubscription.optIn();
    }
    */

    // Register
    // async sendRegisterRequest({ commit }, data) {
    //   commit("setErrors", {}, { root: true });
    //   const response = await axiosInstance.post(backend.AUTH.REGISTER, data);

    //   if (!['error', 'Unconfirmed Account'].includes(response.data.status)) {
    //     // commit("setUserData", response.data.user);
    //     commit("setUserData", response.data.data);
    //     localStorage.setItem("authToken", response.data.token);
    //   }
    //   return response;
    // },


    // sendVerifyResendRequest() {
    //   return axiosInstance.get(process.env.VUE_APP_API_URL + "email/resend");
    // },

    // sendVerifyRequest({ dispatch }, hash) {
    //   return axiosInstance.get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
    //     .then(() => {
    //       dispatch("getUserData");
    //     });
    // }
  }
};
