<template>
  <img :src="src" :width="height" :height="height" />
</template>

<script>
export default {
  name: 'MIcon',
  props: {
    name: String,
    tag: String,
  },
  computed: {
    icons() {
      return [
        {
          "name": "man",
          "tags": ["WALK"],
          "src": "man.svg",
        },
        {
          "name": "bike",
          "tags": ["BICYCLE"],
          "src": "bike.svg"
        },
        {
          "name": "truck",
          "tags": ["DRIVE"],
          "src": "truck.svg",
        },
        {
          "name": "motorcycle",
          "tags": ["TWO_WHEELER"],
          "src": "motorcycle.svg",
        }
      ];
    },
    src() {
      var icon;
      if (this.name) {
        icon = this.icons.find(i => i.name === this.name);
      }

      if (this.tag) {
        icon = this.icons.find(i => i.tags && i.tags.includes(this.tag));
      }

      return icon ? `/assets/micons/${icon.src}` : undefined;
    },
    width() { return this.$attrs.width || "32px"; },
    height() { return this.$attrs.height || "32px"; },
  },
}
</script>

