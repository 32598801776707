import { axiosInstance } from "../helper/axios.js";
import backend from "../router/backend";

const state = {
  restaurants: [],
  selectedRestaurant: null,
};

const mutations = {
  setAllRestaurants(state, restaurants) {
    state.restaurants = restaurants;
  },
  setSelectedRestaurant(state, value) {
    state.selectedRestaurant =
      typeof value === "number"
        ? state.restaurants.find((r) => r.value === value) || value
        : (state.selectedRestaurant = value);
  },
};

const actions = {
  async fetchAllRestaurants({ commit }) {
    if (state.restaurants.length === 0) {
      try {
        const response = await axiosInstance.get(backend.RESTAURANT.GET_ALL_NAME);
        commit("setAllRestaurants", response.data);
        commit("setSelectedRestaurant", state.selectedRestaurant);
      } catch (error) {
        commit("setAllRestaurants", []);
      }
    }
  },
};

const getters = {
  allRestaurants: (state) => state.restaurants,
  selectedRestaurant: (state) => state.selectedRestaurant,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
